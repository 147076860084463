import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {useAuth} from '../auth'
import {useCalendarData} from './core/CalendarDataProvider'
import {Branch} from './core/_models'

function getBusinessDaysName(days: string) {
  const daysArray = []
  if (days.includes('1') === true) daysArray.push('Даваа')
  if (days.includes('2') === true) daysArray.push('Мягмар')
  if (days.includes('3') === true) daysArray.push('Лхагва')
  if (days.includes('4') === true) daysArray.push('Пүрэв')
  if (days.includes('5') === true) daysArray.push('Баасан')
  if (days.includes('6') === true) daysArray.push('Бямба')
  if (days.includes('0') === true) daysArray.push('Ням')
  return daysArray
}

export const WelcomePage: FC = () => {
  const {onlineBookingSettings, branches} = useCalendarData()
  const {settings} = useAuth()
  const [businessDays, setBusinessDays] = useState<any>([])
  const formattedAmount =
    Intl.NumberFormat('en-US').format(
      parseInt(
        onlineBookingSettings?.validate_amount ? onlineBookingSettings?.validate_amount : '0'
      )
    ) + '₮'

  useEffect(() => {
    if (settings?.has_branch) {
      const branchBusinessDays: any[] = []
      branches.map((branch: Branch, index: number) => {
        if (branch.business_days) {
          const result = getBusinessDaysName(branch.business_days)
          branchBusinessDays.push(result)
        }
      })
      setBusinessDays(branchBusinessDays)
    } else {
      if (settings) {
        const result = getBusinessDaysName(settings.business_days)
        setBusinessDays(result)
      }
    }
  }, [branches])

  return (
    <div
      className='d-flex flex-column flex-root welcome'
      data-clr={onlineBookingSettings.theme_color}
    >
      <div className=' welcome-top'>
        <div
          className='d-flex flex-column flex-md-row flex-lg-row'
          style={{
            maxWidth: 1200,
            marginInline: 'auto',
            height: '100%',
          }}
        >
          <div
            className='d-lg-flex flex-lg-row-fluid welcome-img'
            style={{
              backgroundImage: `url("${onlineBookingSettings.image_url}")`,
              backgroundPosition: 'center',
            }}
          ></div>

          <div className='px-7 py-8 mt-10 mb-20 welcom-sideMenu'>
            <div
              className='fw-bolder text-gray-800 mb-8'
              style={{textAlign: 'center', fontSize: '26px', lineHeight: '34px'}}
            >
              {settings?.company_name}
            </div>
            {settings?.has_branch ? (
              <div className='d-grid gap-4 welcome-about-us-content mb-4'>
                {branches.map((branch: Branch, index: number) => {
                  return (
                    <div
                      key={index}
                      className='d-flex flex-column gap-3 welcome-branch-box'
                      style={{
                        border: '1px solid #ddd',
                        borderRadius: '6px',
                        padding: '14px',
                      }}
                    >
                      <div
                        className='fw-bolder text-gray-800 mb-1'
                        style={{textAlign: 'center', fontSize: '16px', lineHeight: '14px'}}
                      >
                        {branch?.name}
                      </div>
                      <div className='d-flex align-items-center gap-3'>
                        <i className='fa fa-solid fa-location-dot welcome-branch-box-icon fs-3'></i>

                        <div className='d-flex gap-2 flex-wrap ps-4'>
                          <span style={{lineHeight: '14px'}}>{branch?.address}</span>
                        </div>
                      </div>
                      {branch?.phone && (
                        <div className='d-flex align-items-center gap-3'>
                          <i className='fa fa-solid fa-phone welcome-branch-box-icon fs-4'></i>
                          <div className='d-flex gap-2 flex-wrap ps-4'>
                            <span style={{lineHeight: '14px'}}>{branch.phone}</span>
                          </div>
                        </div>
                      )}

                      <div className='d-flex align-items-center gap-3'>
                        <i
                          className='fa fa-solid fa-calendar-days welcome-branch-box-icon fs-3'
                          aria-hidden='true'
                        ></i>
                        <div className='d-flex gap-1 flex-wrap ps-4'>
                          {businessDays[index] &&
                            businessDays[index].map((day: string, ind: number) => {
                              return (
                                <span key={ind} style={{lineHeight: '14px'}}>
                                  {day},
                                </span>
                              )
                            })}
                        </div>
                      </div>

                      <div className='d-flex align-items-center gap-3'>

                        <i className='fa fa-solid fa-clock welcome-branch-box-icon fs-3'></i>
                          <div className='d-flex gap-2 flex-wrap ps-4'>
                            <span style={{lineHeight: '14px'}}>
                              {branch?.start_time} - {branch?.end_time}
                            </span>
                          </div>

                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <div className='d-grid gap-4 welcome-about-us-content mb-4'>
                <div
                  className='d-flex flex-column gap-3 welcome-branch-box'
                  style={{
                    border: '1px solid #ddd',
                    borderRadius: '6px',
                    padding: '14px',
                  }}
                >
                  <div className='d-flex align-items-center gap-3'>
                    <i className='fa fa-solid fa-location-dot welcome-branch-box-icon fs-3'></i>
                    <div className='d-flex gap-2 flex-wrap ps-4'>
                      <span style={{lineHeight: '14px'}}>{settings?.address}</span>
                    </div>
                  </div>
                  {settings?.phone && (
                    <div className='d-flex align-items-center gap-3'>
                      <i className='fa fa-solid fa-phone welcome-branch-box-icon fs-4'></i>
                      <div className='d-flex gap-2 flex-wrap ps-4'>
                        <span style={{lineHeight: '14px'}}>{settings.phone}</span>
                      </div>
                    </div>
                  )}
                  <div className='d-flex align-items-center gap-3'>
                    <i
                      className='fa fa-solid fa-calendar-days welcome-branch-box-icon fs-3'
                      aria-hidden='true'
                    ></i>
                    <div className='d-flex gap-1 flex-wrap ps-4'>
                      {businessDays &&
                        businessDays.map((day: string, ind: number) => {
                          return (
                            <span key={ind} style={{lineHeight: '14px'}}>
                              {day},
                            </span>
                          )
                        })}
                    </div>
                  </div>

                  <div className='d-flex align-items-center gap-3'>
                    <i className='fa fa-solid fa-clock welcome-branch-box-icon fs-3'></i>
                    <div className='d-flex gap-2 flex-wrap ps-4'>
                      <span style={{lineHeight: '14px'}}>
                        {settings?.start_time} - {settings?.end_time}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div style={{textAlign: 'center'}}>
              {onlineBookingSettings?.choose_qpay && settings?.use_qpay === 1 && (
                <div className='welcome-warning-text fs-6 mw-400px mb-8 mt-4 mx-auto'>
                  <span>
                    Цаг захиалгыг баталгаажуулах зорилгоор урьдчилгаа төлбөр авахыг анхаарна уу!{' '}
                  </span>
                  <span className='fw-bold'>{formattedAmount}</span>
                </div>
              )}
              <Link to='/booking/service' className='welcome-booking-btn btn mb-xl-0 mb-4'>
                Цаг авах
              </Link>
            </div>
          </div>
        </div>

        {/* ============= WAVE ============ */}
        <div className='wave'>
          <div className='box'></div>
        </div>
        <div className='wave1'>
          <div className='box'></div>
        </div>
      </div>

      <div className='welcome-about'>
        <div
          className='d-flex flex-column flex-md-row flex-lg-row'
          style={{
            maxWidth: 1200,
            marginInline: 'auto',
            height: '100%',
          }}
        >
          <div className='d-flex flex-lg-row py-10 py-md-20'>
            <div className='w-lg-600px w-100 px-7 px-xl-0'>
              <div className='d-inline-block'>
                <div className='welcome-about-us fw-bolder text-gray-800 mt-4'>Бидний тухай</div>
                <div className='separator mx-1 mb-4 mt-1 border-primary '></div>
              </div>
              <div className='welcome-about-us-content' style={{textAlign: 'justify'}}>
                {onlineBookingSettings.about}
              </div>
              <div className='fs-4 mt-5 text-gray-800'>
                <div className='welcome-about-us-content welcome-branch-box-icon fs-5'>
                  {settings?.email && (
                    <div className='pb-1'>
                      <i className='fa fa-solid fa-envelope fs-5 pe-2 welcome-branch-box-icon'></i>
                      <a href={'mailto:' + settings.email} className='welcome-branch-box-icon'>
                        {settings.email}
                      </a>
                    </div>
                  )}

                  <div className='pb-1'>
                    <i className='fa fa-solid fa-globe fs-5 pe-2 welcome-branch-box-icon'></i>
                    <a href='http://xolbooc.com' rel='nofollow' className='welcome-branch-box-icon'>
                      {' '}
                      {' Холбоос'}
                    </a>
                  </div>

                  {(settings?.fb_url || settings?.insta_url) && (
                    <div className='d-flex fb-1 '>
                      <i className='fa fa-solid fa-share-alt fs-4 pe-2 welcome-branch-box-icon pt-1'></i>
                      <div>
                        {settings && settings?.fb_url && (
                          <a
                            href={settings.fb_url}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='fb mx-4'
                          >
                            <KTSVG
                              path='/media/icons/duotune/social/soc004.svg'
                              className='svg-icon-2 welcome-branch-box-icon'
                            />
                          </a>
                        )}
                        {settings && settings?.insta_url && (
                          <a
                            href={settings.insta_url}
                            target='_blank'
                            className='ig'
                            rel='noopener noreferrer'
                          >
                            <KTSVG
                              path='/media/icons/duotune/social/soc005.svg'
                              className='svg-icon-2 welcome-branch-box-icon'
                            />
                          </a>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className='bgi-size-cover bgi-position-y-center bgi-position-x-start bgi-no-repeat welcome-company-img'
            style={{
              backgroundImage: `url("${'/media/mainImg.png'}")`,
            }}
          ></div>
        </div>
      </div>
      {/* =================================================== */}
    </div>
  )
}
